import 'swiper/css'
import 'swiper/css/navigation'
import '../scss/app.scss'
import 'fslightbox'

import { Navigation } from 'swiper/modules'
import Swiper from 'swiper'
import WaveSurfer from 'wavesurfer.js'

// Stories
new Swiper(
    '#stories .swiper',
    {
        loop: true,
        modules: [Navigation],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    }
)

// Photos
new Swiper(
    '#photos .swiper',
    {
        slidesPerView: 5,
        spaceBetween: (16 * 2)  // 2rem
    }
)

document.querySelectorAll('.player').forEach(
    (container) => {
        const wavesurfer = WaveSurfer.create(
            {
              container: container.querySelector('.wavesurfer'),
              waveColor: '#EAEDF1',
              progressColor: '#143E62',
              url: container.getAttribute('data-src')
            }
        )

        container.querySelectorAll('button.play').forEach(
            (button) => {
                button.addEventListener('click',
                    (e) => {
                        e.preventDefault()
                        
                        if (button.disabled) {
                            return
                        }

                        if (wavesurfer.isPlaying()) {
                            wavesurfer.pause()
                        } else {
                            wavesurfer.play()
                        }
                    }
                )

                wavesurfer.on('ready',
                    () => {
                        button.disabled = false
                    }
                )

                wavesurfer.on('pause',
                    () => {
                        container.classList.remove('playing')
                    }
                )

                wavesurfer.on('play',
                    () => {
                        container.classList.add('playing')
                    }
                )
            }
        )
    }
)
